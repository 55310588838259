import { USER_ROLES } from '../constants/roles';

export const canInviteUsers = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageUsers = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canEditIdea = (user) =>  user?.userId?.role === USER_ROLES.Founder;

export const canManageDetails = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageHypothesis = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageBMC = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageTrend = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageIndustry = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageSwot = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageConclusion = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageCompetition = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageActivity = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageTask = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageTargetIndicator = (user) => user?.userId?.role === USER_ROLES.Founder;
