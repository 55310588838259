import { Box, IconButton, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import DialogAlert from '../../../common/components/DialogAlert';
import GenericEmptyTable from '../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../common/components/GenericTableComponent';
import AddTaskModal from '../../../common/modals/AddTaskModal';

import { currentTeamIdSelector } from '../../../common/store';
import { useApi } from '../../../hooks/useApi';
import {
  deleteTaskById,
  getAllTasksByTeam,
  updateTaskById,
} from '../../../requests/tasks';
import { getTeamMembers } from '../../../requests/teams';

import { TASK_STATUS_OPTIONS } from '../../../constants/options';
import { formatDate } from '../../../utils/dateHelpers';
import { handleApiError } from '../../../utils/errorUtils';
import { formatMembersToOptions } from '../../../utils/formatters';
import { canManageTask } from '../../../utils/permissions';

import { EditIcon, TrashIconOutlined } from '../../../assets/icons';

const TasksComponent = () => {
  const intl = useIntl();
  const theme = useTheme();
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const [teamMembers, setTeamMembers] = useState([]);
  const [{ result: tasks }, fetchAllTasksByTeam] = useApi(
    getAllTasksByTeam,
    []
  );
  const [, removeTask] = useApi(deleteTaskById);
  const [, doUpdateTask] = useApi(updateTaskById);
  const loggedUser = useSelector((state) => state.userProfile.userProfile);
  const canManage = canManageTask(loggedUser);

  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [editFormValues, setEditFormValues] = useState(null);
  const [isAddTasksModalOpen, setIsAddTasksModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const fetchTeamMembers = async () => {
    try {
      const response = await getTeamMembers(teamId);
      setTeamMembers(response?.data);
    } catch (e) {
      handleApiError(e);
    }
  };

  const teamMembersOptions = useMemo(() => (
    formatMembersToOptions(teamMembers)
  ), [teamMembers]);

  const handleToggleAddTasksModal = () => {
    setIsAddTasksModalOpen(!isAddTasksModalOpen);
  };

  const handleCloseModal = () => {
    handleToggleAddTasksModal();
    setSelectedTaskId(null);
    setEditFormValues(null);
  };

  const handleGetTasks = async () => {
    const payload = {
      params: { teamId },
      queryParams: { filters: {} },
    };

    await fetchAllTasksByTeam(payload);
  };

  const handleDeleteTask = async () => {
    const payload = {
      params: { taskId: idToDelete },
    };
    await removeTask(payload);
    await handleGetTasks();
    setIdToDelete('');
  };

  const handleEditTask = (id) => {
    const { status, userResponsibleId, successCriteria, title } = tasks.find(
      (activity) => activity._id === id
    );
    setSelectedTaskId(id);
    setEditFormValues({
      state: status,
      userResponsibleId,
      successCriteria,
      title,
    });
    handleToggleAddTasksModal();
  };

  const handleUpdateTask = async (formValues) => {
    const payload = {
      params: { taskId: selectedTaskId },
      activityData: {
        ...formValues,
        status: formValues.state,
        completionDate: Date.now(),
        cost: +formValues?.cost,
      },
    };
    await doUpdateTask(payload);
    await handleGetTasks();
    handleCloseModal();
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: intl.formatMessage({ id: 'label.task' }), key: 'title' },
    { type: 'string', content: intl.formatMessage({ id: 'label.cost' }), key: 'cost' },
    // { type: 'string', content: 'Responsabil', key: 'userResponsibleId' },
    { type: 'string', content: intl.formatMessage({ id: 'label.study' }), key: 'status' },
    { type: 'string', content: intl.formatMessage({ id: 'label.finalized' }), key: 'completionDate' },
    // { type: 'string', content: 'Cale', key: 'way' },
    ...(tasks.length && canManage
      ? [{ type: 'string', content: '', hideSortIcon: true }]
      : []),
  ];

  const createRows = () =>
    tasks.map(
      (
        {
          _id,
          title,
          // userResponsibleId,
          status,
          completionDate,
          cost,
        },
        index
      ) => ({
        rowId: _id,
        order: index + 1,
        title,
        cost,
        // userResponsibleId,
        status: TASK_STATUS_OPTIONS.find((option) => option.value === status)
          .label,
        completionDate: formatDate(completionDate),
        ...(canManage ? {
          action: (
            <Box sx={{ display: 'flex', gap: '18px' }}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setIdToDelete(_id);
                }}
                color="error"
              >
                <TrashIconOutlined />
              </IconButton>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditTask(_id);
                }}
              >
                <EditIcon size="18px" fill={theme.palette.general.accentLight} />
              </IconButton>
            </Box>
          ),
        } : {}),
      })
    );

  useEffect(() => {
    if (teamId) {
      handleGetTasks();
      fetchTeamMembers();
    }
  }, []);

  return (
    <>
      {tasks && !!tasks.length
        ? <GenericTableComponent rows={createRows()} headerCells={createTableHeaderCells()} />
        : (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          onAddClick={handleToggleAddTasksModal}
          detailMessage={`${intl.formatMessage({ id: 'label.noTaskWasAdded' })}...`}
        />
      )}
      <AddTaskModal
        handleClose={handleToggleAddTasksModal}
        handleUpdate={handleUpdateTask}
        isModalOpen={isAddTasksModalOpen}
        teamMembers={teamMembersOptions}
        formValues={editFormValues}
      />
      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteTask' })}
          hasActions
          onSubmit={handleDeleteTask}
        />
      )}
    </>
  );
};

export default TasksComponent;
