import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DetailsDrawerWrapper from '../../../../common/components/DetailsDrawerWrapper';
import DialogAlert from '../../../../common/components/DialogAlert';
import { getAllTrendsByTeamId } from '../../../../store/contextAnalysis';
import AddTrendsModal from '../../components/AddTrendsModal';
import TrendDetails from '../../components/TrendDetails';
import TrendsList from '../../components/TrendsList';
import TrendsPageHeader from '../../components/TrendsPageHeader';

import { currentTeamIdSelector } from '../../../../common/store';
import { useApi } from '../../../../hooks/useApi';
import { deleteTrendById } from '../../../../requests/trends';

import { canManageTrend } from '../../../../utils/permissions';
import { CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';

const TrendsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeTrend, setActiveTrend] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    isTrendFavorable: true,
    isTrendUnfavorable: true,
  });
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);
  const [{ isLoading: isDeleting }, deleteTrend] = useApi(deleteTrendById);
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const trendList = useSelector((state) => state.contextAnalysis.teamTrends || []);

  const handleViewTrend = (trend) => {
    setIsDrawerOpen(true);
    setSelectedTrend(trend);
  };

  const handleEditTrend = (trend) => {
    setSelectedTrend(trend);
    setIsDialogOpen(true);
    setIsDrawerOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedTrend(null);
  };

  const handleOpen = () => setIsDialogOpen(true);

  const handleOpenDialog = (trendId) => {
    handleOpen();

    if (trendId) {
      setActiveTrend(trendId);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setActiveTrend('');
  };

  const handleDeleteTrend = async () => {
    await deleteTrend({ params: { trendId: activeTrend } });
    dispatch(getAllTrendsByTeamId(teamId));
    setIsDrawerOpen(false);
    setSelectedTrend(null);
    handleCloseDialog();
  };

  const handleCheck = ({ target: { name, checked } }) => {
    setSelectedFilters({
      ...selectedFilters,
      [name]: checked,
    });
  };

  const formattedFilters = useMemo(() => {
    if (!selectedFilters?.isTrendFavorable && selectedFilters?.isTrendUnfavorable) {
      return { isTrendFavorable: [false, false] };
    }

    if (selectedFilters?.isTrendFavorable && !selectedFilters?.isTrendUnfavorable) {
      return { isTrendFavorable: [true, true] };
    }

    return {};
  }, [selectedFilters]);

  useEffect(() => {
    if (teamId) {
      dispatch(getAllTrendsByTeamId(teamId, { filters: formattedFilters }));
    }
  }, [teamId, formattedFilters]);

  return (
    <>
      <TrendsPageHeader
        onCheck={handleCheck}
        selectedFilters={selectedFilters}
        openAddTrendModal={handleOpen}
        isFilterVisible={trendList?.length > 0}
        canAddTrend={canManageTrend(loggedUser)}
      />
      <TrendsList
        onEditTrend={handleEditTrend}
        onDeleteTrend={handleOpenDialog}
        onViewTrend={handleViewTrend}
        trendList={trendList}
        canManageCard={canManageTrend(loggedUser)}
      />
      {/*TODO: send entity id once we clarify how comments should work*/}
      <DetailsDrawerWrapper
        isDrawerOpen={isDrawerOpen}
        onCloseDrawer={handleCloseDrawer}
        onEdit={() => handleEditTrend(selectedTrend)}
        onDelete={() => handleOpenDialog(selectedTrend?._id)}
        commentType={CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB.trends}
        drawerTitle={intl.formatMessage({ id: 'label.visualizeTrend' })}
        canEdit={canManageTrend(loggedUser)}
      >
        <TrendDetails trend={selectedTrend}/>
      </DetailsDrawerWrapper>
      <AddTrendsModal
        teamId={teamId}
        selectedTrend={selectedTrend}
        setSelectedTrend={setSelectedTrend}
        isModalOpen={isDialogOpen && !activeTrend}
        setIsModalOpen={setIsDialogOpen}
      />
      <DialogAlert
        isModalOpen={isDialogOpen && !!activeTrend}
        onClose={handleCloseDialog}
        onSubmit={handleDeleteTrend}
        hasActions
        isDisabled={isDeleting}
        title={intl.formatMessage({ id: 'modal.title.deleteTrend' })}
        content={intl.formatMessage({ id: 'modal.content.deleteTrend' })}
      />
    </>
  );
};

export default TrendsPage;
