import {
  OutlinedInput,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { CHART_COLORS } from '../../constants';

import { DefaultCell, TableBody } from './index.styled';

const EditableCell = ({
  initialValue,
  type = 'number',
  isHeader = false,
  cellData,
  updateCell,
  postNewCompetitor,
  handleEditCompetitor,
  handleAddCriteria,
  handleEditCriteria,
  handleDeleteCompetitor,
  handleDeleteCriteria,
  canManageCompetition,
  placeholder = 'placeholder.add',
  id,
  color,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(initialValue);

  const handleFocusOut = (e) => {
    const { competitorId, criteriaId } = cellData.coordinates;
    const { action } = cellData;

    if (action) {
      if (action === 'addCompetitor') {
        postNewCompetitor(e.target.value);
      }

      if (action === 'editCompetitorName') {
        if (!e.target.value) {
          handleDeleteCompetitor(competitorId);
          return;
        }

        handleEditCompetitor(e.target.value, competitorId);
      }

      if (action === 'addCriteria') {
        handleAddCriteria(e.target.value);
      }

      if (action === 'editCriteriaName') {
        if (!e.target.value) {
          handleDeleteCriteria(criteriaId);
          return;
        }

        handleEditCriteria && handleEditCriteria(e.target.value, criteriaId);
      }

      return;
    }

    if (criteriaId && competitorId) {
      if (+e.target.value === initialValue) {
        setValue(initialValue);
        return;
      }

      if (e.target.value === '') {
        setValue(initialValue);
        return;
      }

      updateCell({
        competitorId,
        criteriaId,
        value: e.target.value || initialValue,
        initialValue,
      });
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
  };

  const handleChangeNumber = (event) => {
    let inputValue = event.target.value;

    if (inputValue !== '') {
      inputValue = parseInt(inputValue, 10);

      setValue(inputValue);
    } else {
      setValue(0);
    }
  };

  const defaultValues = [0, 'Adauga un criteriu', 'Adauga un competitor'];
  const checkForDefaultValue = (value) => {
    if (defaultValues.includes(value)) {
      return '';
    }
    return value;
  };

  return (
    <DefaultCell isHeader={isHeader}>
      {cellData?.label
        ? (
          <Typography ml={16}>
            {cellData?.label}
          </Typography>
        ) : (
          <OutlinedInput
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
              },
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
              '& input[type="number"]': {
                MozAppearance: 'textfield',
              },
              color: color || 'black',
              ...(!canManageCompetition
                ? {
                  '& .Mui-disabled': {
                    opacity: 1,
                    '-webkit-text-fill-color': color || 'black',
                  },
                } : {}),
            }}
            type={type}
            value={checkForDefaultValue(value)}
            onChange={type === 'number' ? handleChangeNumber : handleChange}
            disabled={canManageCompetition ? cellData.disabled : true}
            onBlur={handleFocusOut}
            placeholder={intl.formatMessage({ id: placeholder })}
            id={id}
          />
        )
      }
    </DefaultCell>
  );
};

export const CompetitionTable = ({
  rows = [],
  handleCellUpdate,
  postNewCompetitor,
  handleEditCompetitor,
  handleAddCriteria,
  handleEditCriteria,
  handleDeleteCompetitor,
  handleDeleteCriteria,
  canManageCompetition,
}) => {
  if (rows.length === 0) return <div />;

  return (
    <TableContainer sx={{ marginTop: '20px' }} component={Paper} elevation={0}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {rows[0].map((header) => (
              <EditableCell
                id={header.id}
                key={header.id}
                initialValue={header.value}
                placeholder="placeholder.addACriteria"
                type="text"
                isHeader
                cellData={header}
                updateCell={handleCellUpdate}
                handleAddCriteria={handleAddCriteria}
                handleEditCriteria={handleEditCriteria}
                handleDeleteCriteria={handleDeleteCriteria}
                canManageCompetition={canManageCompetition}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(1).map((row, index) => (
            <TableRow key={index}>
              {row.map((rowElement, idx) =>
                idx === 0 ? (
                  <EditableCell
                    id={`cell-${idx}${index}`}
                    key={rowElement.id}
                    initialValue={rowElement.value}
                    type="text"
                    isHeader
                    cellData={rowElement}
                    updateCell={handleCellUpdate}
                    postNewCompetitor={postNewCompetitor}
                    handleEditCompetitor={handleEditCompetitor}
                    handleDeleteCompetitor={handleDeleteCompetitor}
                    placeholder="placeholder.addACompetitor"
                    color={CHART_COLORS[index]}
                    canManageCompetition={canManageCompetition}
                  />
                ) : (
                  <EditableCell
                    id={`${index}-${rowElement.id}`}
                    key={rowElement.id}
                    initialValue={rowElement.value}
                    cellData={rowElement}
                    updateCell={handleCellUpdate}
                    placeholder="placeholder.completeFrom1To5"
                    canManageCompetition={canManageCompetition}
                  />
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
