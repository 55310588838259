import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AddButton from '../../components/AddButton';
import DialogAlert from '../../components/DialogAlert';
import DrawerComponent from '../../components/DrawerComponent';
import GenericEmptyTable from '../../components/GenericEmptyTable';
import GenericTableComponent from '../../components/GenericTableComponent';
import AddDetailsModal from '../../modals/AddDetailsModal';

import {
  deleteDetail,
  editDetail,
  getAllDetails,
  postDetail,
} from '../../../store/details/detailsSlice';
import { currentTeamIdSelector } from '../../store';

import { BMC_TYPE } from '../../../constants/bmc';
import { extendedCategoryOptions } from '../../../constants/options';
import { formatDetailForServer } from '../../../utils/detailUtils';
import { searchLabel } from '../../../utils/labels';
import { canManageDetails } from '../../../utils/permissions';

import { TrashIconOutlined } from '../../../assets/icons';

import ContentDetailDrawer from '../../drawers/ContentDetailDrawer';

const DetailsTable = ({ section = 'hypotheses', hypothesis = {} }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const details = useSelector((state) => state.details.details);
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);

  const [isAddDetailsModalOpen, setIsAddDetailsModalOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const handleOpenDialog = () => setIsAddDetailsModalOpen(true);

  const handleOpenDrawer = () => setIsDrawerOpen(true);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedDetail(null);
  };

  const handleGetDetails = async () => {
    const filters = hypothesis?._id
      ? { section, hypothesisId: hypothesis?._id }
      : { section };

    await dispatch(getAllDetails(teamId, filters));
  };

  const handleDeleteDetail = async () => {
    await dispatch(deleteDetail(idToDelete));
    await handleGetDetails();
    setIdToDelete('');
  };

  const handleSuccessAdd = async () => {
    handleCloseDialog();
    await handleGetDetails();
  };

  const handleSuccessUpdate = async (updatedData) => {
    handleCloseDialog();
    setSelectedDetail(updatedData);
    await handleGetDetails();
  };

  const handleDetail = async (formValues, isUpdate = false) => {
    const adjustedValues = formatDetailForServer(formValues);
    const payload = hypothesis?._id
      ? {
        ...adjustedValues,
        section,
        hypothesisId: hypothesis?._id,
      } : { ...adjustedValues, section };

    if (isUpdate) {
      await dispatch(editDetail(payload, selectedDetail?._id, handleSuccessUpdate));
    } else {
      await dispatch(postDetail(payload, teamId, handleSuccessAdd));
    }
  };

  const formatDate = (initialDate) => {
    const date = new Date(initialDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based, so we add 1
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const createRows = () =>
    details.map(({ title, category, createdAt, _id }, index ) => {
      const categoryLabel = searchLabel(category, extendedCategoryOptions);
      return {
        rowId: _id,
        number: index + 1,
        title,
        category: categoryLabel ? intl.formatMessage({ id: categoryLabel }) : '',
        createdAt: formatDate(createdAt),
        ...(canManageDetails(loggedUser) ? {
          action: (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setIdToDelete(_id);
              }}
              color="error"
            >
              <TrashIconOutlined />
            </IconButton>
          ),
        } : {}),
      };
    }
  );

  const createTableHeaderCells = () => {
    const header = [
      { type: 'string', content: intl.formatMessage({ id: 'label.number' }), key: 'number' },
      { type: 'string', content: intl.formatMessage({ id: 'label.designation' }), key: 'description' },
      { type: 'string', content: intl.formatMessage({ id: 'label.category' }), key: 'category' },
      { type: 'string', content: `${intl.formatMessage({ id: 'label.createdAt' })}`, key: 'createdAt' },
  ];

    if (canManageDetails(loggedUser)) {
      header.push({
        type: 'component',
        content: (
          <AddButton
            id="add-detail"
            onClick={handleOpenDialog}
            p={5}
          />
        ),
        hideSortIcon: true,
      });
    }

    return header;
  };

  const handleSelectedDetail = (id) => {
    const detailData = details.find((detail) => detail._id === id);
    setSelectedDetail(detailData);
    handleOpenDrawer();
  };

  const handleCloseDialog = () => setIsAddDetailsModalOpen(false);

  const handleDeleteElement = () => {
    setSelectedDetail(null);
    handleGetDetails();
  };

  useEffect(() => {
    if (teamId) {
      handleGetDetails();
    }
  }, [teamId]);

  return (
    <>
      {details && !!details.length ? (
        <GenericTableComponent
          rows={createRows()}
          headerCells={createTableHeaderCells()}
          handleSelectedRow={handleSelectedDetail}
        />
      ) : (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          detailMessage={intl.formatMessage({ id: 'label.addAFirstDetailToStartValidating' })}
        />
      )}
      <AddDetailsModal
        teamId={teamId}
        isModalOpen={isAddDetailsModalOpen}
        handleClose={handleCloseDialog}
        handleSubmitDetail={(values) => handleDetail(values, false)}
        formValues={selectedDetail}
        hypothesisId={hypothesis?._id}
        handleUpdateDetail={(values) => handleDetail(values, true)}
        hasExtendedCategories={hypothesis?.columnBMC === BMC_TYPE.customerSegments}
      />

      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteDetail' })}
          hasActions
          onSubmit={handleDeleteDetail}
        />
      )}

      {/*TODO: send entity id once we clarify how comments should work*/}
      <DrawerComponent
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
      >
        <ContentDetailDrawer
          initialDetail={selectedDetail}
          onCloseDrawer={handleCloseDrawer}
          onEdit={handleOpenDialog}
          onSuccessDelete={handleDeleteElement}
        />
      </DrawerComponent>
    </>
  );
};

export default DetailsTable;
