import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import AddButton from '../../../../common/components/AddButton';
import { ClampedText } from '../../../../common/components/ClampedText';

import {
  EyeIcon,
  EditIcon,
  TrashIconOutlined,
} from '../../../../assets/icons';

export const SwotCard = ({
  swotSegmentData,
  handleAddSwotElement,
  swotSegmentDetails,
  handleDeleteSwotElement,
  handleEditSwotElement,
  handleViewSwotElement,
  canManageSwot,
}) => {
  const intl = useIntl();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
          position: 'sticky',
          top: 0,
          backgroundColor: '#FFF',
          padding: '15px 0',
        }}
      >
        <Typography variant="h2" color="primary">
          {intl.formatMessage({ id: swotSegmentDetails.label })}
        </Typography>
        {canManageSwot && (
          <AddButton
            p={5}
            id={swotSegmentDetails.value}
            onClick={() => handleAddSwotElement(swotSegmentDetails.value)}
          />
        )}
      </Box>

      {swotSegmentData.length > 0 &&
        swotSegmentData.map((swot) => (
          <Box
            key={swot._id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '1px solid #B1CCFE',
              borderRadius: '10px',
              padding: '5px 10px',
              marginBottom: '5px',
              '&:hover .actions': {
                opacity: 1,
              },
            }}
          >
            <Typography color="primary">
              <ClampedText text={swot.description} lineClamp={2} />
            </Typography>
            <Box
              className="actions"
              sx={{ opacity: 0, transition: 'opacity 0.3s', display: 'flex' }}
            >
              <IconButton onClick={() => handleViewSwotElement(swot)}>
                <EyeIcon fill="#74A3FE" />
              </IconButton>
              {canManageSwot && (
                <>
                  <IconButton onClick={() => handleEditSwotElement(swot)}>
                    <EditIcon size="18px" fill="#74A3FE" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleDeleteSwotElement(swot._id);
                    }}
                    color="error"
                  >
                    <TrashIconOutlined />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};
