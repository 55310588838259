import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {ClampedRichText} from '../../../common/components/ClampedRichText';
import DialogAlert from '../../../common/components/DialogAlert';
import GenericEmptyTable from '../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../common/components/GenericTableComponent';

import { currentTeamIdSelector } from '../../../common/store';
import { useApi } from '../../../hooks/useApi';
import {
  deleteConclusionById,
  getAllConclusionsByTeam,
} from '../../../requests/conclusions';

import {canManageConclusion} from '../../../utils/permissions';
import {ACTIVITY_CONCLUSION_SEGMENT_OPTIONS} from '../../hypothesisActivities/constants';

import { ThumbsUpIcon, TrashIconOutlined } from '../../../assets/icons';

const ResolutionCell = ({ favorable, experimentType }) => {
  const intl = useIntl();
  const defaultStyle = {
    padding: 0,
    width: '20px',
    ...(!favorable && { transform: 'rotate(180deg)' }),
  };

  return (
    <Box sx={{ display: 'flex', gap: '7px' }}>
      <Box sx={defaultStyle}>
        <ThumbsUpIcon size="20px" fill={favorable ? 'green' : 'red'} />
      </Box>
      {experimentType && (
        <Box>{intl.formatMessage({ id: experimentType })}</Box>
      )}
    </Box>
  );
};

const ConclusionsComponent = () => {
  const intl = useIntl();
  const [idToDelete, setIdToDelete] = useState('');
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const [{ result: teamConclusions }, fetchConclusions] = useApi(getAllConclusionsByTeam, []);
  const [, removeConclusions] = useApi(deleteConclusionById);
  const loggedUser = useSelector((state) => state.userProfile.userProfile);
  const canManage = canManageConclusion(loggedUser);

  const handleFetchConclusions = () => {
    const payload = {
      params: { teamId },
      queryParams: { filters: {} },
    };
    fetchConclusions(payload);
  };

  const handleDeleteConclusion = async () => {
    const payload = {
      params: { conclusionId: idToDelete },
    };
    await removeConclusions(payload);
    await handleFetchConclusions();
    setIdToDelete('');
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: intl.formatMessage({ id: 'label.conclusion' }), key: 'title' },
    { type: 'string', content: intl.formatMessage({ id: 'label.observations' }), key: 'description' },
    { type: 'string', content: intl.formatMessage({ id: 'label.resolution' }), key: 'status' },
    // { type: 'string', content: 'Cale', key: 'path' },
    ...(teamConclusions.length && canManage
      ? [{ type: 'string', content: '', key: 'delete', hideSortIcon: true }]
      : []),
  ];

  const renderExperimentType = (experimentType) => (
    ACTIVITY_CONCLUSION_SEGMENT_OPTIONS.find((option) => option.value === experimentType)?.label
  );

  const createRows = () =>
    teamConclusions.map(
      ({ _id, title, description, status, experimentType }, index) => ({
        rowId: _id,
        order: index + 1,
        title : (
          <Box>
            <ClampedRichText text={title} lineClamp={1} />
          </Box>
        ),
        description: (
          <Box>
            <ClampedRichText text={description} lineClamp={1} />
          </Box>
        ),
        status: (
          <ResolutionCell
            favorable={status !== 'negative'}
            experimentType={renderExperimentType(experimentType)}
          />
        ),
        ...(canManage ? {
          action: (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setIdToDelete(_id);
              }}
              color="error"
            >
              <TrashIconOutlined />
            </IconButton>
          ),
        } : {}),
      })
    );

  useEffect(() => {
    if (teamId) {
      handleFetchConclusions();
    }
  }, []);

  return (
    <>
      {teamConclusions && !!teamConclusions.length
        ? <GenericTableComponent rows={createRows()} headerCells={createTableHeaderCells()} />
        : (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          detailMessage={`${intl.formatMessage({ id: 'label.noConclusionWasAdded' })}...`}
        />
      )}
      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteConclusion' })}
          hasActions
          onSubmit={handleDeleteConclusion}
        />
      )}
    </>
  );
};

export default ConclusionsComponent;
