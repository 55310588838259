import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DialogAlert from '../../../common/components/DialogAlert';
import GenericEmptyTable from '../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../common/components/GenericTableComponent';

import { currentTeamIdSelector } from '../../../common/store';
import { deleteHypothesis, loadHypotheses } from '../../../store/hypothesis/hypothesisSlice';

import { BMC_LABELS } from '../../../constants/labels';
import {canManageHypothesis} from '../../../utils/permissions';

import { TrashIconOutlined } from '../../../assets/icons';

const HypothesisComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [idToDelete, setIdToDelete] = useState('');

  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const loggedUser = useSelector((state) => state.userProfile.userProfile);
  const hypotheses = useSelector((state) => state.hypotheses.hypotheses) || [];
  const canManage = canManageHypothesis(loggedUser);

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: intl.formatMessage({ id: 'label.designation' }), key: 'description' },
    { type: 'string', content: intl.formatMessage({ id: 'label.BMC' }), key: 'columnBMC' },
    { type: 'string', content: intl.formatMessage({ id: 'label.importance' }), key: 'importance' },
    // { type: 'string', content: 'Cost', key: 'cost' }, ??????
    // {
    //   type: 'component',
    //   content: <Box>icon</Box>,
    //   hideSortIcon: true,
    //   key: 'ceva'
    // },
    // { type: 'string', content: 'Concluzie', key: 'conclusion' },
    ...(hypotheses.length && canManage
      ? [{ type: 'string', content: '', key: 'delete', hideSortIcon: true }]
      : []),
  ];

  const handleDeleteHypothesis = async () => {
    await dispatch(deleteHypothesis(idToDelete));
    await dispatch(loadHypotheses(teamId));
    setIdToDelete('');
  };

  const createRows = () =>
    hypotheses.map(
      (
        {
          _id,
          description,
          columnBMC,
          // createdAt,
          importance,
          // activityType,
          // problemSeverity,
          // levelOfInterest,
          // contactDisponibility,
        },
        index
      ) => {
        const columnBMCLabel = BMC_LABELS?.[columnBMC] || '';

        return {
          rowId: _id,
          order: index + 1,
          description,
          columnBMC: columnBMCLabel ? intl.formatMessage({ id: columnBMCLabel }) : '-',
          importance,
          // activityType: searchLabel(activityType, activityTypeOptions),
          // category: searchLabel(category, categoryOptions),
          // problemSeverity:
          //   activityType === 'minutaInterviuClienti' && problemSeverity
          //     ? searchLabel(+problemSeverity, problemSeverityOptions)
          //     : '-',
          // levelOfInterest: determineLevelOfInterest(
          //   activityType,
          //   levelOfInterest
          // ),
          // contactDisponibility: determineContactDisponibility(
          //   activityType,
          //   contactDisponibility
          // ),
          // createdAt: formatDate(createdAt),
          // creator: <CreatorCell firstName={firstName} lastName={lastName} />, // TO add here profile picture from above
          ...(canManage ? {
            action: (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setIdToDelete(_id);
                }}
                color="error"
              >
                <TrashIconOutlined />
              </IconButton>
            ),
          } : {}),
        };
      }
    );

  useEffect(() => {
    if (teamId) {
      dispatch(loadHypotheses(teamId));
    }
  }, []);
  return (
    <>
      {hypotheses && !!hypotheses.length ? (
        <GenericTableComponent
          rows={createRows()}
          headerCells={createTableHeaderCells()}
        />
      ) : (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          detailMessage={`${intl.formatMessage({ id: 'label.noHypothesisAdded' })}...`}
        />
      )}

      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteHypothesis' })}
          hasActions
          onSubmit={handleDeleteHypothesis}
        />
      )}
    </>
  );
};

export default HypothesisComponent;
