import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {connect, useDispatch, useSelector} from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogAlert from '../../../../common/components/DialogAlert';
import FilterButton from '../../../../common/components/FilterButton';
import { deleteCompetitionMetric } from '../../../../requests/contextAnalysis';
import {
  getCompetitorMetrics,
  postNewCompetitor,
  postCompetitorMetric,
  postNewCriteria,
  editCompetitor,
  editCriteria,
  deleteCriteria,
  deleteCompetitor,
} from '../../../../store/contextAnalysis';
import { ChartFilters } from '../../components/ChartFilters';
import { CompetitionChart } from '../../components/CompetitionChart';
import { CompetitionTable } from '../../components/CompetitionTable';

import { currentTeamIdSelector } from '../../../../common/store';
import { notifyError, notifySuccess } from '../../../../core/store';

import { handleApiError } from '../../../../utils/errorUtils';
import {canManageCompetition} from '../../../../utils/permissions';
import {
  formatChartData,
  generateFullCompetitionRows,
} from '../../utils';

import { TrashIconOutlined } from '../../../../assets/icons';

const Competition = ({
  getCompetitorMetrics,
  teamId,
  postNewCompetitor,
  postNewCriteria,
  teamCompetitorMetrics,
  postCompetitorMetric,
  editCompetitor,
  editCriteria,
  deleteCriteria,
  deleteCompetitor,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const loggedUser = useSelector((state) => state.userProfile.userProfile);

  const chartValues = [...rows].splice(1, 5);

  const formattedChartData = formatChartData(
    chartValues,
    teamCompetitorMetrics
  );
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleCheck = (event) => {
    selectedFilters.includes(event.target.value)
      ? setSelectedFilters(
          selectedFilters.filter((filter) => filter !== event.target.value)
        )
      : setSelectedFilters([...selectedFilters, event.target.value]);
  };

  useEffect(() => {
    if (teamId) {
      getCompetitorMetrics(teamId);
    }
  }, [getCompetitorMetrics, teamId]);

  useEffect(() => {
    if (teamCompetitorMetrics) {
      const rows = generateFullCompetitionRows(teamCompetitorMetrics);
      setRows(rows);
    }
  }, [teamCompetitorMetrics]);

  const handleNewCompetitor = (competitorName) => {
    postNewCompetitor({ name: competitorName }, teamId);
  };
  const handleEditCompetitor = (competitorName, competitorId) => {
    editCompetitor({ name: competitorName }, teamId, competitorId);
  };

  const handleAddCriteria = (criteriaName) => {
    if (criteriaName) {
      postNewCriteria({ name: criteriaName }, teamId);
    }
  };

  const handleEditCriteria = (criteriaName, criteriaId) => {
    editCriteria({ name: criteriaName }, teamId, criteriaId);
  };

  const handleDeleteCriteria = (criteriaId) => {
    deleteCriteria(criteriaId, teamId);
  };

  const handleDeleteCompetitor = (competitorId) => {
    deleteCompetitor(competitorId, teamId);
  };

  const handleCellUpdate = (cellData) => {
    const { competitorId, criteriaId, value } = cellData;

    if (+value > 5 || +value < 1) {
      const message = intl.formatMessage({ id: 'validate.pickNumberBetween' }, { min: 1, max: 5 });
      dispatch(notifyError({ message }));
      return;
    }

    postCompetitorMetric({ competitorId, criteriaId, value: +value }, teamId);
  };

  const handleDeleteTable = async () => {
    setIsSubmitting(true);

    try {
      await deleteCompetitionMetric(teamId);
      await getCompetitorMetrics(teamId);
      handleCloseDialog();
      dispatch(notifySuccess(intl.formatMessage({ id: 'success.tableDeletedSuccessfully' })));
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDialog = () => setIsOpen(true);

  const handleCloseDialog = () => setIsOpen(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant="h2" color="primary">
            {intl.formatMessage({ id: 'label.competitionChart' })}
          </Typography>
          <Typography variant="body1" className="faded" color="primary" mt={10}>
            {`${intl.formatMessage({ id: 'label.completeCriteriaCompetitors' })}...`}
          </Typography>
        </Box>
        <FilterButton counter={selectedFilters?.length}>
          {() => (
            <ChartFilters
              handleCheck={handleCheck}
              selectedFilters={selectedFilters}
              formattedChartData={formattedChartData}
              competitorMetrics={teamCompetitorMetrics}
            />
          )}
        </FilterButton>
      </Box>
      <CompetitionChart
        teamCompetitorMetrics={teamCompetitorMetrics}
        chartValues={formattedChartData}
        selectedFilters={selectedFilters}
      />
      <Box sx={{ marginTop: '50px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2" color="primary">
            {intl.formatMessage({ id: 'label.competitorAnalysis' })}
          </Typography>
          {canManageCompetition(loggedUser) && (
            <ButtonComponent
              variant="outlined"
              color="error"
              onClick={handleOpenDialog}
              icon={<TrashIconOutlined fill="currentColor" size="14px" />}
              label={intl.formatMessage({ id: 'button.emptyTable' })}
            />
          )}
        </Box>
        <Typography variant="body1" className="faded" color="primary" mt={10}>
          {intl.formatMessage({ id: 'label.addNewCriteriaByClicking' })}
        </Typography>
      </Box>
      <CompetitionTable
        postNewCompetitor={handleNewCompetitor}
        rows={rows}
        handleCellUpdate={handleCellUpdate}
        handleEditCompetitor={handleEditCompetitor}
        handleAddCriteria={handleAddCriteria}
        handleEditCriteria={handleEditCriteria}
        handleDeleteCriteria={handleDeleteCriteria}
        handleDeleteCompetitor={handleDeleteCompetitor}
        canManageCompetition={canManageCompetition(loggedUser)}
      />

      {isOpen && (
        <DialogAlert
          isModalOpen={isOpen}
          onClose={handleCloseDialog}
          onSubmit={handleDeleteTable}
          hasActions
          isDisabled={isSubmitting}
          submitLabel="button.clean"
          title={intl.formatMessage({ id: 'modal.title.deleteCompetitionTable' })}
          content={intl.formatMessage({ id: 'modal.content.deleteCompetitionTable' })}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  teamCompetitorMetrics: state.contextAnalysis.teamCompetitorMetrics,
});

const mapDispatchToProps = {
  getCompetitorMetrics,
  postNewCompetitor,
  postCompetitorMetric,
  postNewCriteria,
  editCompetitor,
  editCriteria,
  deleteCriteria,
  deleteCompetitor,
};

export default connect(mapStateToProps, mapDispatchToProps)(Competition);
