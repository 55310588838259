import { Box, Divider } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import GenericComments from '../../../modules/commentsAndNotifications/components/GenericComments';

import { getAllComments } from '../../../modules/commentsAndNotifications/store';
import { currentTeamIdSelector } from '../../store';

import { DRAWER_TABS } from '../../../constants/drawerTabs';

import { EditIcon, TrashIconOutlined } from '../../../assets/icons';

import ButtonComponent from '../ButtonComponent';
import DrawerComponent from '../DrawerComponent';
import DrawerTabs from '../DrawerTabs';
import DrawerTitle from '../DrawerTitle';

const DetailsDrawerWrapper = ({
  isDrawerOpen,
  onCloseDrawer,
  onEdit,
  onDelete,
  commentType,
  children,
  drawerTitle,
  canEdit = false,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);
  const currentUserId = useSelector(
    (state) =>
      state.userProfile.userProfile && state.userProfile.userProfile.userId._id
  );
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const contextAnalysisComments = useSelector((state) => state.comments[commentType]) || [];

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: { commentType },
        teamId,
        userId: currentUserId,
      })
    );
  };

  return (
    <DrawerComponent
      isOpen={isDrawerOpen}
      onClose={onCloseDrawer}
    >
      <DrawerTitle
        title={drawerTitle}
        onClose={onCloseDrawer}
      />
      <DrawerTabs
        sx={{ mt: 4 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
      />

      {activeDrawerTab === DRAWER_TABS.details ? (
        <Box mt={20} gap={20} display="flex" flexDirection="column">
          {children}

          <Divider />

          {canEdit && (
            <Box display="flex" alignItems="center" gap={10}>
              <ButtonComponent
                onClick={onEdit}
                variant="outlined"
                color="secondary"
                icon={<EditIcon fill="currentColor" size="14px" />}
                label={intl.formatMessage({ id: 'button.edit' })}
              />
              <ButtonComponent
                onClick={onDelete}
                icon={<TrashIconOutlined size="16px" />}
                color="error"
                label={intl.formatMessage({ id: 'button.delete' })}
              />
            </Box>
          )}
        </Box>
      ) : (
        <GenericComments
          fetchComments={fetchComments}
          type={commentType}
          teamId={teamId}
          userId={currentUserId}
          comments={contextAnalysisComments}
        />
      )}
    </DrawerComponent>
  );
};

export default DetailsDrawerWrapper;
