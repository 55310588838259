import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import DetailsCard from '../../../../common/components/DetailsCard';

import { UpwardsTrend } from '../../../../assets/icons';

import { IconWrapper } from './index.styled';

const TrendsList = ({
  trendList,
  onViewTrend,
  onEditTrend,
  onDeleteTrend,
  canManageCard,
}) => {
  const intl = useIntl();

  return (
    <Box my={trendList?.length > 0 ? 20 : 10}>
      {trendList?.length > 0 ? (
        <Grid container spacing={20}>
          {trendList.map((trend) => (
              <Grid item xs={12} sm={6} md={4} key={trend._id}>
                <DetailsCard
                  title={trend.title}
                  description={trend.description}
                  onView={() => onViewTrend(trend)}
                  onEdit={() => onEditTrend(trend)}
                  onDelete={() => onDeleteTrend(trend?._id)}
                  canManageCard={canManageCard}
                >
                  <IconWrapper isTrendFavorable={trend.isTrendFavorable}>
                    <UpwardsTrend fill='currentColor' />
                  </IconWrapper>
                </DetailsCard>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Typography className="italic" color="textSecondary">
          {intl.formatMessage({ id: 'label.addFirstTrendFor' })}
        </Typography>
      )}
    </Box>
  );
};

export default TrendsList;
